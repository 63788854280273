import React from "react"

import { useTranslation } from "react-i18next"

import { useMediaQuery } from "react-responsive"

import css from "./Subline.module.scss"

const Subline = ({ image, subline = "", icons = [] }) => {
  const { t } = useTranslation()
  const [mainIcon, ...otherIcons] = icons
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" })
  return (
    <section className={css.container}>
      <div className={css.left} style={{ backgroundImage: `url(${image})` }} />
      <div className={css.right}>
        {icons && icons.length > 0 && (
          <div className={css.icons}>
            <div style={{ backgroundImage: `url(${mainIcon.image})` }} />
            {otherIcons &&
              otherIcons.length > 0 &&
              otherIcons.map(({ image }, i) => (
                <div key={i} style={{ backgroundImage: `url(${image})` }} />
              ))}
          </div>
        )}
        <div className={css.inner}>
          {isMobile && otherIcons.length > 2 && (
            <svg
              className={css.icon}
              width="92"
              height="38"
              viewBox="0 0 92 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 19L91 19" stroke="white" />
              <path d="M71.5 37.5L90.5 19L71.5 0.500001" stroke="white" />
              <path d="M20.5 0.5L1.5 19L20.5 37.5" stroke="white" />
            </svg>
          )}
          {/*<div className={css.title}>{t('goals_detail_subline')}</div>*/}
          <div
            className={css.text}
            dangerouslySetInnerHTML={{ __html: subline }}
          />
        </div>
      </div>
    </section>
  )
}

export default Subline
