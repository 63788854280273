import React from "react"

import PageGoalDetail from "components/PageGoalDetail/PageGoalDetail"

import Layout from "components/Layout/Layout"

import useGoals from "hooks/useGoals"
import useChangeLanguage from "hooks/useChangeLanguage"

const Goals = ({ pageContext: { slug, articles, targets, lang } }) => {
  useChangeLanguage(lang)
  const goals = useGoals()
  const goal = goals.results.find(item => item.slug === slug)

  return (
    <Layout color="white">
      <PageGoalDetail
        goal={goal}
        articles={articles}
        targets={targets}
        slug={slug}
      />
    </Layout>
  )
}

export default Goals
