import React from "react"

import { useTranslation } from "react-i18next"

import css from "./Hero.module.scss"

const Hero = ({
  image,
  title,
  slug,
  subline = "Поддержка местных сообществ через развитие социальных инвестиций и благотворительных проектов",
}) => {
  const { t } = useTranslation()
  return (
    <section
      className={css.container}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={css.content}>
        <p className={css.textSmallCaps}>{t("x5 goal")}</p>
        <h2>{title}</h2>
        <p>{subline}</p>
      </div>
      <span className={css.button}>
        {t(`goals_detail_hero_button_${slug}`)}
        <span />
      </span>
    </section>
  )
}

export default Hero
