import React from 'react'

import { useTranslation } from 'react-i18next'

import ProgramItem from './ProgramItem/ProgramItem'

import css from './Programs.module.scss'

const Programs = ({ programs }) => {
  const { t } = useTranslation()

  if (!programs || programs.length === 0) return null
  return (
    <section className={css.container}>
      <div className={css.head}>{t('programs')}</div>
      <div className={css.list}>
        {programs.map(({ slug, title, previewImage, previewDescription }) => (
          <ProgramItem
            key={slug}
            slug={slug}
            title={title}
            previewImage={previewImage}
            previewDescription={previewDescription}
            className={css.item}
          />
        ))}
      </div>
    </section>
  )
}

export default Programs
