import React from 'react'

import TargetItem from '../TargetItem'

import css from './TargetsList.module.scss'

const TargetsList = ({ targets }) => {
  return <div className={css.wrapper}>
    {targets.map(item => <TargetItem {...item} key={item.id} />)}
  </div>
}

export default TargetsList
