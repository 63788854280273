import React, { useRef, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Link } from 'gatsby'

import classes from 'utils/classes'

import css from './ProgramItem.module.scss'
import { useTranslation } from "react-i18next"

const ProgramItem = ({ title, previewImage, previewDescription, slug, className }) => {
  const text = useRef()
  const [margin, setMargin] = useState(0)
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  const { i18n: { language } } = useTranslation()

  const resize = () => {
    const { height } = text.current.getBoundingClientRect()
    setMargin(isMobile ? 0 : height)
  }

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <div className={classes(css.item, className)} style={{ marginTop: `${margin}px` }}>
      <Link to={`/${language}/programs/${slug}/`}>
        <div className={css.title} ref={text}>{title} <span>→</span></div>
      </Link>
      <div className={css.image} style={{ backgroundImage: `url(${previewImage})` }} />
      <div className={css.text} dangerouslySetInnerHTML={{ __html: previewDescription }} />
      <Link to={`/${language}/programs/${slug}/`} className={css.link} />
    </div>
  )
}

export default ProgramItem
