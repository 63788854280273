import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

import TargetsList from 'components/common/Targets/TargetsList'

import useContents from 'hooks/useContents'
import useUrlPrefix from 'hooks/useUrlPrefix'

import css from './TargetsList.module.scss'

const TargetsGoalList = ({ targets }) => {
  const { t } = useTranslation()
  const contents = useContents()
  const urlPrefix = useUrlPrefix()

  if (targets.length === 0) return null

  return <div className={css.wrapper}>
    <div className={css.top}>
      <h3>{contents.targetsTitle || t('targets_title')}</h3>

      <Link
        to={urlPrefix('/targets/')}
        className={'small'}
      >
        {t('all_targets')}
      </Link>
    </div>
    <TargetsList targets={targets} />
  </div>
}

export default TargetsGoalList
