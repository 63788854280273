import React from 'react'

import css from './TargetItem.module.scss'

const Heading = ({ title }) => {
  if (!title || title.length === 0) return null

  const inner = title
    .replace('(', `<span class="${css.upper}">`)
    .replace(')', '</span>')
    .replace('[', `<span class="${css.sub}">`)
    .replace(']', '</span>')

  return <div className={css.heading} dangerouslySetInnerHTML={{ __html: inner }} />
}

const Direction = ({ direction }) => {
  if (direction === 'top') return <svg className={css.direction} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#5FAF2D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9 15.0215V19L15 14.9785L21 19V15.0215L15 11L9 15.0215Z" fill="white"/>
  </svg>

  if (direction === 'still') return <svg className={css.direction} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.9999" cy="15" r="15" fill="#5FAF2D"/>
    <path d="M14.9999 25L14.9999 5" stroke="white"/>
    <path d="M4.99988 15H24.9999" stroke="white"/>
  </svg>

  return <svg className={css.direction} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#5FAF2D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21 16.166V12.1875L15 16.209L9 12.1875V16.166L15 20.1875L21 16.166Z" fill="#5FAF2D"/>
  </svg>
}

const Icon = ({ icon }) => {
  if (!icon || icon === 'None') return null

  if (icon === 'top') return <svg className={css.icon} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="34.9999" cy="35" r="34.5" stroke="black"/>
    <rect x="34.7" y="54.2996" width="36.6" height="0.6" transform="rotate(-90 34.7 54.2996)" fill="white" stroke="black" strokeWidth="0.6"/>
    <path d="M22.4 29.3994L35 17.3994L47.6 29.3994" stroke="black"/>
  </svg>

  if (icon === 'still') return <svg className={css.icon} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="34.5" stroke="black"/>
    <rect x="34.5001" y="10.0001" width="1" height="50" fill="black"/>
    <rect x="10" y="35.5001" width="1" height="50" transform="rotate(-90 10 35.5001)" fill="black"/>
  </svg>

  if (icon === 'wide') return <svg className={css.icon} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="34.5" stroke="black"/>
    <rect x="34.75" y="27.6371" width="16.2222" height="0.500001" transform="rotate(-90 34.75 27.6371)" fill="white" stroke="black" strokeWidth="0.500001"/>
    <path d="M27.2102 18.5835L35.0002 11.1645L42.7901 18.5835" stroke="black"/>
    <rect x="42.3616" y="34.7498" width="16.2222" height="0.500001" fill="white" stroke="black" strokeWidth="0.500001"/>
    <path d="M51.415 27.2098L58.8341 34.9998L51.415 42.7898" stroke="black"/>
    <rect x="35.25" y="42.3629" width="16.2222" height="0.500001" transform="rotate(90 35.25 42.3629)" fill="white" stroke="black" strokeWidth="0.500001"/>
    <path d="M42.7898 51.4165L34.9998 58.8355L27.2099 51.4165" stroke="black"/>
    <rect x="27.6382" y="35.2499" width="16.2222" height="0.500001" transform="rotate(180 27.6382 35.2499)" fill="white" stroke="black" strokeWidth="0.500001"/>
    <path d="M18.5847 42.7898L11.1657 34.9998L18.5847 27.2099" stroke="black" strokeWidth="0.833334"/>
  </svg>

  return null
}

const TargetItem = ({ title, heading, icon, direction }) => {
  return <div className={css.wrapper}>
    <div className={css.top}>
      <Heading title={heading} />
      <Icon icon={icon} />

      <Direction direction={direction} />
    </div>
    <div className={css.title}>{title}</div>
  </div>
}

export default TargetItem
