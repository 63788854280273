import React from 'react'
import Hero from './Hero/Hero'
import Subline from './Subline/Subline'
import Programs from './Programs/Programs'
import NewsList from '../PageIndex/NewsList/NewsList'
import TargetsList from './TargetsList'
import SEO from 'components/seo'
import classNames from './PageGoalDetail.module.scss'

const GoalDetail = ({ goal, articles, targets }) => {
  const {
    banner, name, title, svgImages, image, content, programs, slug, id,
  } = goal
  const link = goal.funds

  return <>
    <SEO title={goal.metaTitle || name} description={goal.metaDescription} keywords={goal.metaKeywords}/>
    <Hero image={banner} title={name} subline={title} slug={slug} />
    <Subline icons={svgImages} image={image} subline={content} />
    <TargetsList targets={targets.results} />
    { goal.funds?.length > 0 && (
      <div className={classNames.fond}>
        { goal.funds.map((item) => {
          const link = item.url.match(/(?:https?:\/\/)?([^\/]+)/)?.[1];

          return (
            <div
              key={item.id}
              className={classNames.fondBlock}
            >
              <div className={classNames.fondImage}>
                <img
                  src={item.image}
                  alt="Логотип Фонда"
                  className={classNames.fondImageItem}
                />
              </div>
              <div className={classNames.fondDescription}>
                <p className={classNames.fondTitle}>
                  { item.title }
                </p>
                <div className={classNames.fondText} dangerouslySetInnerHTML={{ __html: item.description }} />
                { link && (
                  <a
                    href={item.url}
                    target="_blank"
                  >{ link }</a>
                ) }
              </div>
            </div>
          );
        }) }
      </div>
    ) }
    <Programs programs={programs} />
    <NewsList list={articles.results} count={articles.count} filter={{ goal: id }} grey />
  </>
}

export default GoalDetail
